import React from "react";
import styled from "styled-components";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import {
  MERGE_PRICING_PAGE,
  MERGE_SUPPORTD_FEATURES_TABLE,
} from "components/docs/navigation/links";
import {
  FlexRow,
  LinkTitle,
  CrossGuideLink,
  ChevronLeft,
} from "components/docs/navigation/CrossGuideLinks";
import { Integration, RouterLocation } from "types/types";
import DocumentationSection from "../../../components/docs/DocumentationSection";
import HeaderBar from "../../../components/docs/layout/HeaderBar";
import DocsHelmet from "../../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../../components/docs/shared-components/PageContainers";
import ConfigMergeToYou from "../../../assets/images/docs/webhooks/ConfigMergeToYou.svg";
import WebhookReceivers from "../../../assets/images/docs/webhooks/WebhookReceivers.svg";
import WebhookReceiverConfig from "../../../assets/images/docs/webhooks/WebhookReceiverConfig.svg";

const PseudoPaddedSection = styled.section`
  :target {
    scroll-margin-top: 120px;
  }
`;

const StyledFlexRow = styled(FlexRow)`
  justify-content: flex-start;
`;

const OL = styled.ol`
  counter-reset: item;
  padding-left: 15px;
`;

const LI = styled.li`
  list-style-type: disc;
`;

interface DocumentationWebhooksThirdPartyToMergePageProps {
  location: RouterLocation;
}

export enum WebhookTypes {
  automatic = "automatic",
  manual = "manual",
}

export const getIntegrationsThatSupportWebhooksForCategory = (
  integrations: Integration[],
  category: string,
  webhookType: string,
) =>
  integrations.filter(
    (integration) =>
      integration.categories.includes(category) &&
      (webhookType === WebhookTypes.automatic
        ? integration.isAutoWebhookSetupEnabled
        : integration.isManualWebhookSetupEnabled),
  );

interface AllIntegrations {
  allIntegrations: {
    nodes: Array<Integration>;
  };
}

const DocumentationWebhooksThirdPartyToMergePage = ({
  location,
}: DocumentationWebhooksThirdPartyToMergePageProps) => {
  const title = "Third-party webhooks";
  const description =
    "Configure webhooks in supported third parties that send data payloads to Merge.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={`${title} sent to Merge`} subtitle={description}>
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our <b>Professional</b> or{" "}
          <b>Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <PseudoPaddedSection id="overview">
        <DocumentationSection title="Overview">
          <p>
            When an event occurs in a third-party platform, such as a change to end user data, that
            platform can be configured to send Merge a webhook with a data payload to give you
            real-time updates regardless of the rate at which Merge syncs data.
          </p>
          <p className="mb-0">
            Follow the setup instructions below to enable Merge to receive third-party webhooks for
            a linked account.
          </p>
        </DocumentationSection>
      </PseudoPaddedSection>
      <hr className="my-9" />
      <PseudoPaddedSection id="automatic-setup">
        <DocumentationSection title="Automatic setup">
          <p>
            Merge is able to automatically create webhooks in supported third-party APIs to alert
            Merge of changes. This means neither you nor your end user needs to do the manual setup
            of a webhook per Linked Account.{" "}
            <a href={MERGE_SUPPORTD_FEATURES_TABLE} target="_blank">
              See what integrations support automatic webhook creation.
            </a>
          </p>
          <p>Automatic webhooks are created on Merge’s initial sync with a third-party platform.</p>
          <p>
            Automatic webhooks are not enabled by default. To enable automatic webhooks, go to{" "}
            <b>
              Advanced {">"} Webhooks {">"} Third Party
            </b>{" "}
            in your dashboard to toggle this feature on.
          </p>
          <div className="flex justify-center mt-9 mb-9">
            <ConfigMergeToYou className="rounded-lg shadow-md" />
          </div>
          <p>
            <strong>To enable / disable webhooks for a specific Linked Account</strong>, go to the{" "}
            <b>Webhooks</b> tab under a specific Linked Account.
          </p>
          <p>
            You will see a purple notice that says
            <strong>
              <b> Merge automatically created this webhook in [App Name].</b>
            </strong>
          </p>
          <p>
            Enable or disable the <b>Merge receiver</b> (an endpoint in Merge’s API that receives
            third-party data) to control whether Merge updates your data with payloads from the
            connected app’s webhooks.
          </p>
          <div className="flex justify-center mt-9 mb-9">
            <WebhookReceivers className="rounded-lg shadow-md" />
          </div>
        </DocumentationSection>
      </PseudoPaddedSection>
      <hr className="my-9" />
      <PseudoPaddedSection id="manual-setup">
        <DocumentationSection title="Manual setup">
          <div>
            <p>
              There are a few third-party applications that do not support automatic webhook
              creation via API, but do support manual webhook creation via UI.{" "}
              <a href={MERGE_SUPPORTD_FEATURES_TABLE} target="_blank">
                See what integrations support automatic webhook creation.
              </a>
            </p>
            <p>The only way to set up webhooks for these integrations is: </p>
            <OL>
              <li>
                <b>
                  Have your end user manually configure the webhook subscriptions in their
                  third-party app instance.
                </b>
                <p className="mt-2">
                  See our Help Center for guides to configure webhooks in the third party.
                </p>
              </li>
              <li>
                <b>Manually configure third-party webhooks in Merge</b>
                <p className="mt-2">
                  Navigate to Linked Accounts and select an account integrated with a third-party
                  platform that supports third-party webhooks.
                </p>
                <p className="mt-2">
                  Go to the <b>Webhooks</b> tab of the Linked Account to:
                </p>
                <ul>
                  <LI>
                    Access the <b>Merge Receiver URLs</b> and <b>Signature Keys</b>
                  </LI>
                  <LI>Configure which events Merge will listen to</LI>
                </ul>
              </li>
            </OL>
          </div>
          <div className="flex justify-center mt-9 mb-9">
            <WebhookReceiverConfig className="rounded-lg shadow-md" />
          </div>
          <p>
            Once your webhooks are configured, the third-party application will send webhooks to the{" "}
            <b>Merge Receiver URLs.</b>
          </p>
          <p>
            For each webhook request, Merge will immediately process the incoming data and save the
            updated model(s).
          </p>
          <p>
            Webhooks will be sent from Merge to your system for all relevant <b>Merge Webhooks</b>{" "}
            you have set up, allowing real-time updates to flow from the third-party app all the way
            to your system.
          </p>
        </DocumentationSection>
      </PseudoPaddedSection>
      <div className="pt-10 w-full mb-20 ml-auto mr-auto max-w-[1600px]">
        <StyledFlexRow>
          <div>
            <>
              <LinkTitle>Previous</LinkTitle>
              <CrossGuideLink to="/basics/webhooks/merge-webhooks">
                <ChevronLeft />
                Merge To You
              </CrossGuideLink>
            </>
          </div>
        </StyledFlexRow>
      </div>
    </StaticPageContainer>
  );
};

export default DocumentationWebhooksThirdPartyToMergePage;
